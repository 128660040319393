const { searchPaths } = require('../search/search-paths-cjs')

const detailPropertyTypeSlug =
  ':propertyTypes(apartments|condos|townhomes|houses|apartment|condo|townhome|house|duplex|triplex|rental)'

module.exports = function rewrites() {
  return [
    // When trailing slashes are enabled, in middleware,
    // NextResponse.rewrite() seems to cause the router.push()
    // calls to fully refresh. This is a fallback solution for
    // supporting AG/trailing slashes while migrating between
    // pages router and app router.
    ...searchPaths.map((source) => ({
      source,
      destination: `/search`,
    })),
    {
      source: `/${detailPropertyTypeSlug}/:state/:city/:propertyName/:listingId`,
      destination: '/detail',
    },
    {
      source: '/(a|rent)/(.*)-:listingId',
      destination: '/detail',
    },
    {
      source: '/list-a-property-for-rent',
      destination: '/list',
    },
    {
      source: `/${detailPropertyTypeSlug}/:state/:city/:propertyName/:listingId/review`,
      destination: '/review',
    },
  ]
}
